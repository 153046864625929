/**
 * Get query params from the URL.
 *
 * @returns
 */
export function getStaticQueryParams(): Record<string, string> {
  if (!window || !window.location || !window.location.search) {
    return {};
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}
