import {
  Alert,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import type { NextPageWithLayout } from './_app';
import { useRouter } from 'next/router';
import { MouseEventHandler, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import authState, { oauth } from '@/store/auth';
import logo from '@/static/images/logo.svg';
import microsoftMark from '@/static/images/3rd-party/microsoft-mark.svg';
import { FiAlertCircle } from '@react-icons/all-files/fi/FiAlertCircle';
import { FcGoogle } from '@react-icons/all-files/fc/FcGoogle';
import { FaGithub } from '@react-icons/all-files/fa/FaGithub';
import { upperFirst, useToggle } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { getTimedGreeting } from '@/utils/greet';
import { showNotification } from '@mantine/notifications';
import { useFinalColorScheme } from '@/hooks/mantine';
import classes from './login.module.css';
import { getStaticQueryParams } from '@/utils/url';

const errorDescriptions: Record<string, any> = {
  access_denied: 'OAuth request was denied by the user',
};

function Login() {
  const colorScheme = useFinalColorScheme();
  const { replace } = useRouter();
  const query = getStaticQueryParams();

  const [type, toggle] = useToggle(['login', 'register']);
  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      password: '',
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      password: (val) =>
        val.length <= 6
          ? 'Password should include at least 6 characters'
          : null,
    },
  });

  const [socialAuthLoading, setSocialAuthLoading] = useState(
    !!query.ticket ||
      !!new URLSearchParams(window.location.search).get('ticket'),
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(
    (query.error_description || query.error) as any,
  );
  const [userPassLoading] = useState(false);

  useEffect(() => {
    setSocialAuthLoading(!!query.ticket);
    if (query.ticket) {
      oauth
        .handleTicketResponse()
        .then((session) => authState.onAuthentication(session!));
    }
  }, [query.ticket]);

  useEffect(() => {
    if (!query.return_url) {
      return;
    }
    window.sessionStorage.setItem('return_url', query.return_url as string);
  }, [query.return_url]);

  useEffect(() => {
    setErrorMessage((query.error_description || query.error) as any);
  }, [query.error_description, query.error]);

  const isLoginDisabled = socialAuthLoading || userPassLoading;
  const handleSocialAuth: MouseEventHandler<HTMLButtonElement> = async (
    event,
  ) => {
    setSocialAuthLoading(true);
    await authState.signIn(event.currentTarget.value);
  };

  return (
    <Container fluid className={classes.container}>
      <Center className={classes.centerContainer}>
        <Stack gap="md" align="center" className={classes.stack}>
          {errorMessage && (
            <Alert
              classNames={{ root: classes.alertRoot }}
              radius="md"
              icon={<FiAlertCircle size={16} />}
              title="Could not login"
              color="red"
              onClose={() => {
                replace('/login');
              }}
              withCloseButton
            >
              {errorDescriptions[errorMessage] || errorMessage}
            </Alert>
          )}
          <Paper
            className={classes.formContainer}
            radius="md"
            p="xl"
            style={{ position: 'relative' }}
          >
            <LoadingOverlay visible={socialAuthLoading} />
            <img
              src={logo}
              alt="mockingjay.io Logo"
              className={classes.brandLogo}
            />

            <Text size="xl" fw={200}>
              {getTimedGreeting()} :)
            </Text>
            <Text c="dimmed" size="md" fw={200}>
              {upperFirst(type)} with,
            </Text>
            <Group gap="xs" grow mb="md" mt="sm">
              <Button
                disabled={isLoginDisabled}
                onClick={handleSocialAuth}
                value="google"
                leftSection={<FcGoogle size={18} />}
                variant={colorScheme === 'light' ? 'default' : 'light'}
                color="gray"
                fullWidth
              >
                Google
              </Button>
              <Button
                disabled={isLoginDisabled}
                onClick={handleSocialAuth}
                value="github"
                leftSection={<FaGithub size={18} />}
                variant={colorScheme === 'light' ? 'default' : 'light'}
                color="gray"
                fullWidth
              >
                GitHub
              </Button>
              <Button
                disabled={isLoginDisabled}
                onClick={handleSocialAuth}
                value="microsoft"
                leftSection={
                  <img src={microsoftMark} alt="microsoft logo" width={18} />
                }
                variant={colorScheme === 'light' ? 'default' : 'light'}
                color="gray"
                fullWidth
              >
                Microsoft
              </Button>
            </Group>

            <Divider
              label="Or continue with email"
              labelPosition="center"
              my="lg"
            />

            <form
              onSubmit={form.onSubmit(() => {
                showNotification({
                  color: 'red',
                  title: 'Forbidden',
                  message: 'User not allowed to login at the moment.',
                });
              })}
            >
              <Stack>
                {type === 'register' && (
                  <TextInput
                    label="Name"
                    placeholder="Your name"
                    value={form.values.name}
                    onChange={(event) =>
                      form.setFieldValue('name', event.currentTarget.value)
                    }
                  />
                )}

                <TextInput
                  required
                  label="Email"
                  type="email"
                  placeholder="hello@example.com"
                  value={form.values.email}
                  onChange={(event) =>
                    form.setFieldValue('email', event.currentTarget.value)
                  }
                  error={form.errors.email && 'Invalid email'}
                />

                <PasswordInput
                  required
                  label="Password"
                  placeholder="Your password"
                  value={form.values.password}
                  onChange={(event) =>
                    form.setFieldValue('password', event.currentTarget.value)
                  }
                  error={
                    form.errors.password &&
                    'Password should include at least 6 characters'
                  }
                />

                {type === 'register' && (
                  <Checkbox
                    label="I accept terms and conditions"
                    checked={form.values.terms}
                    onChange={(event) =>
                      form.setFieldValue('terms', event.currentTarget.checked)
                    }
                  />
                )}
              </Stack>

              <Group justify="right" mt="xl">
                {/*<Anchor*/}
                {/*  component="button"*/}
                {/*  type="button"*/}
                {/*  c="dimmed"*/}
                {/*  onClick={() => toggle()}*/}
                {/*  size="xs"*/}
                {/*>*/}
                {/*  {type === 'register'*/}
                {/*    ? 'Already have an account? Login'*/}
                {/*    : "Don't have an account? Register"}*/}
                {/*</Anchor>*/}
                <Button type="submit" disabled>
                  {upperFirst(type)}
                </Button>
              </Group>
            </form>
          </Paper>
        </Stack>
      </Center>
    </Container>
  );
}

(Login as NextPageWithLayout).getLayout = (page) => {
  return page;
};

export default observer(Login);
